import Vue from 'vue/dist/vue.esm'
import VCalendar from 'v-calendar'
import TurbolinksAdapter from 'vue-turbolinks'
import _ from 'lodash'
import Moment from 'moment'
import AxiosRequest from "../utils/axios-request"
# Local imports
import Redscheduled from '../methods/services/redscheduled'

Vue.use TurbolinksAdapter
Vue.use VCalendar


document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'rescheduled'
  return false unless el?

  dev = document.getElementById('dev').dataset.dev

  if JSON.parse dev
    router_api = 'http://dev.allkryon.com:3000/'
  else
    router_api = 'https://api.kryon.digital/'

  new Vue {
    el
    data: -> {
      id: undefined
      uid: undefined
      date: undefined
      service: []
      available_dates: undefined
      available_times: undefined
      holidays: undefined
      last_service: undefined
      time_select: undefined
      attributes: [
        {
          dot: 'blue'
          dates: undefined
          popover: {
            label: 'Día festivo'
          }
        },
        {
          bar: true,
          bar: 'blue',
          dates: new Date()
          popover: {
            label: 'Hoy'
          }
        }
      ]
    }
    methods: {
      Redscheduled
      availableDate: (service) ->
        $.get("#{router_api}/api/v1/services/#{service.item_id}/available_dates.json").done (data) =>
          find_dates = data.available_dates.find((e) => e.store.id == service.store_id)
          @available_dates = find_dates?.dates.map((e) => Moment(e).format('YYYY-M-DD'))
      availableHour: (date) ->
        if date != null
          query = $.get("#{router_api}/api/v1/services/#{@service.item_id}/stores/#{@service.store_id}/hours", { date: Moment(date).format('YYYY-MM-DD') })
          query.done (data) =>
            @available_times = data.hours.map((e) => { hour: Moment(e).format('h:mm A'), main: e})
          query.fail (data) =>
            Swal.fire({
              toast: true,
              position: 'top-end'
              icon: 'error'
              text: data.responseJSON.message
              showConfirmButton: false
              timer: 4000
            })
        else
          Swal.fire({
            toast: true,
            position: 'top-end'
            icon: 'error'
            text: 'selecciona una fecha'
            showConfirmButton: false
            timer: 4000
          })
      getHolidays: () ->
        $.get("/holidays.json").done (data) =>
          @holidays = data.holidays.map((e) => new Date(Moment(e.date).format('YYYY, M, DD')))
          @attributes[0].dates = @holidays
    }
    mounted: ->
      @getHolidays()
      @id = document.getElementById('id').dataset.id
      @uid = document.getElementById('uid').dataset.uid
      query = $.get "/scheduled/#{@uid}/manager_services/#{@id}.json"
      query.done (res) =>
        @service = res.i_service
        @date = Moment(res.i_service.date).format('YYYY-M-DD')
        @availableDate(res.i_service)
        @availableHour(Moment(res.i_service.date).format('YYYY-M-DD'))
    watch: {
      date: (val) ->
        @time_select = undefined
        @availableHour(val)
    }
  }
