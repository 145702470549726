import AxiosRequest from "../../utils/axios-request"

export default (id) ->
  self = this
  if self.time_select == undefined && self.date == null
    Swal.fire({
      toast: true,
      position: 'top-end'
      icon: 'error'
      text: 'Selecciona una fecha y una hora disponible'
      showConfirmButton: false
      timer: 4000
    })
  else if self.date == null
    Swal.fire({
      toast: true,
      position: 'top-end'
      icon: 'error'
      text: 'selecciona una fecha'
      showConfirmButton: false
      timer: 4000
    })
  else if self.time_select == undefined
    Swal.fire({
      toast: true,
      position: 'top-end'
      icon: 'error'
      text: 'Selecciona una hora'
      showConfirmButton: false
      timer: 4000
    })
  else
    AxiosRequest("/scheduled/#{self.uid}/manager_services/rescheduled", {select_date: self.date, select_time: self.time_select, id: id}, 'post').then ({data}) =>
      Swal.fire({
        toast: true,
        timerProgressBar: true
        position: 'top-end'
        icon: 'success'
        text: data.message
        showConfirmButton: false
        timer: 2000
      }).then =>
        window.location.replace("/scheduled/#{self.uid}/agends/services")
